import Head from "next/head";
import React from "react";

const Page404: React.FC = () => {
  return (
    <div className="text-center flex flex-1 min-h-screen items-center justify-center flex-col relative bg-gradient-to-r from-red-400 to-red-custom">
      <Head>
        <title>orange: page not found</title>
      </Head>
      <h1
        className={"text-white font-ApfelGrotezk font-bold"}
        style={{
          fontSize: "12vw",
        }}
      >
        ~404 Not Found~
      </h1>
    </div>
  );
};

export default Page404;
